var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-screen container mx-auto min-contents" }, [
    _c("div", { staticClass: "w-full rounded overflow-hidden my-4" }, [
      _c("div", { staticClass: "px-6 py-4" }, [
        _c("h2", { staticClass: "font-bold text-2xl text-center mb-4" }, [
          _vm._v(_vm._s(_vm.$t("privacypolicy.section1.title"))),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "container w-10/12 mx-auto mt-6 mb-10 overflow-hidden" },
      [
        _c("div", { staticClass: "mb-12 pb-12 border-b-2" }, [
          _c("p", [_vm._v(_vm._s(_vm.$t("privacypolicy.section1.text01")))]),
          _c("p", [_vm._v(_vm._s(_vm.$t("privacypolicy.section1.text02")))]),
          _c("ul", { staticClass: "list-decimal mt-8 ml-8 mb-8" }, [
            _c("li", { staticClass: "mb-4" }, [
              _vm._v(_vm._s(_vm.$t("privacypolicy.section1.list01"))),
            ]),
            _c("li", { staticClass: "mb-4" }, [
              _vm._v(_vm._s(_vm.$t("privacypolicy.section1.list02"))),
            ]),
            _c("li", { staticClass: "mb-4" }, [
              _vm._v(_vm._s(_vm.$t("privacypolicy.section1.list03"))),
            ]),
            _c("li", { staticClass: "mb-4" }, [
              _vm._v(_vm._s(_vm.$t("privacypolicy.section1.list04"))),
            ]),
            _c("li", { staticClass: "mb-4" }, [
              _vm._v(_vm._s(_vm.$t("privacypolicy.section1.list05"))),
            ]),
            _c("li", { staticClass: "mb-4" }, [
              _vm._v(_vm._s(_vm.$t("privacypolicy.section1.list06"))),
            ]),
            _c("li", { staticClass: "mb-4" }, [
              _vm._v(_vm._s(_vm.$t("privacypolicy.section1.list07"))),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "w-1/2 sm:w-1/2 md:w-1/4 ml-0 mr-0 mb-0 ml-auto" },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("privacypolicy.section1.establishment"))
              ),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.$t("privacypolicy.section1.revised"))),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.$t("privacypolicy.common.company"))),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.$t("privacypolicy.common.ceo"))),
              _c("br"),
            ]
          ),
        ]),
        _c("div", { staticClass: "mb-12 pb-12 border-b-2" }, [
          _c("p", { staticClass: "text-lg font-bold mb-4" }, [
            _vm._v(_vm._s(_vm.$t("privacypolicy.section2.title"))),
          ]),
          _c("ul", { staticClass: "list-none ml-8 mb-8" }, [
            _c("li", { staticClass: "mb-1" }, [
              _vm._v(_vm._s(_vm.$t("privacypolicy.common.inquiriesdesk"))),
            ]),
            _c("li", { staticClass: "mb-1" }, [
              _vm._v(_vm._s(_vm.$t("privacypolicy.common.email"))),
            ]),
            _c("li", { staticClass: "mb-1" }, [
              _vm._v(_vm._s(_vm.$t("privacypolicy.common.operationhour"))),
            ]),
            _c("li", { staticClass: "mb-1" }, [
              _vm._v(_vm._s(_vm.$t("privacypolicy.common.remark"))),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mb-12 pb-12 border-b-2" }, [
          _c("p", { staticClass: "text-lg font-bold mb-4" }, [
            _vm._v(_vm._s(_vm.$t("privacypolicy.section3.title"))),
          ]),
          _c("ul", { staticClass: "list-decimal ml-8 mb-8" }, [
            _c("li", { staticClass: "mb-16" }, [
              _c("p", { staticClass: "mb-4 text-lg" }, [
                _vm._v(_vm._s(_vm.$t("privacypolicy.section3.column01.title"))),
              ]),
              _c(
                "table",
                {
                  staticClass:
                    "table-auto border-collapse border border-slate-400 mt-4 ml-0 mb-8",
                },
                [
                  _c("tbody", [
                    _c("tr", [
                      _c("td", { staticClass: "border border-slate-300 p-4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacypolicy.section3.column01.tabletitle01"
                            )
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "border border-slate-300 p-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "privacypolicy.section3.column01.tablecontent01"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "border border-slate-300 p-4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacypolicy.section3.column01.tabletitle02"
                            )
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "border border-slate-300 p-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "privacypolicy.section3.column01.tablecontent02"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "border border-slate-300 p-4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacypolicy.section3.column01.tabletitle03"
                            )
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "border border-slate-300 p-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "privacypolicy.section3.column01.tablecontent03"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("tr", [
                      _c("td", { staticClass: "border border-slate-300 p-4" }, [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "privacypolicy.section3.column01.tabletitle04"
                            )
                          )
                        ),
                      ]),
                      _c("td", { staticClass: "border border-slate-300 p-4" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "privacypolicy.section3.column01.tablecontent04"
                              )
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c("ul", { staticClass: "list-none ml-0 mb-8" }, [
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("privacypolicy.section3.column01.list01"))
                  ),
                ]),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("privacypolicy.section3.column01.list02"))
                  ),
                ]),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("privacypolicy.section3.column01.list03"))
                  ),
                ]),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("privacypolicy.section3.column01.list04"))
                  ),
                ]),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("privacypolicy.section3.column01.remark01"))
                  ),
                ]),
                _c("li", { staticClass: "mb-2" }, [
                  _vm._v(
                    _vm._s(_vm.$t("privacypolicy.section3.column01.remark02"))
                  ),
                ]),
              ]),
            ]),
            _c("li", { staticClass: "mb-16" }, [
              _c("p", { staticClass: "mb-4 text-lg" }, [
                _vm._v(_vm._s(_vm.$t("privacypolicy.section3.column02.title"))),
              ]),
              _c("div", { staticClass: "mb-4" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("privacypolicy.section3.column02.topics01.text")
                    )
                  ),
                ]),
                _c("ul", { staticClass: "list-none mt-4 ml-4 mb-8" }, [
                  _c("li", { staticClass: "mb-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column02.topics01.list01"
                        )
                      )
                    ),
                  ]),
                  _c("li", { staticClass: "mb-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column02.topics01.list02"
                        )
                      )
                    ),
                  ]),
                  _c("li", { staticClass: "mb-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column02.topics01.list03"
                        )
                      )
                    ),
                  ]),
                  _c("li", { staticClass: "mb-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column02.topics01.list04"
                        )
                      )
                    ),
                  ]),
                  _c("li", { staticClass: "mb-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column02.topics01.list05"
                        )
                      )
                    ),
                  ]),
                  _c("li", { staticClass: "mb-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column02.topics01.list06"
                        )
                      )
                    ),
                  ]),
                  _c("li", { staticClass: "mb-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column02.topics01.list07"
                        )
                      )
                    ),
                  ]),
                  _c("li", { staticClass: "mb-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column02.topics01.list08"
                        )
                      )
                    ),
                  ]),
                  _c("li", { staticClass: "mb-2" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column02.topics01.list09"
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "mb-4" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("privacypolicy.section3.column02.topics02.text")
                    )
                  ),
                ]),
              ]),
              _c("div", { staticClass: "mb-4" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(
                      _vm.$t("privacypolicy.section3.column02.topics03.text")
                    )
                  ),
                ]),
              ]),
            ]),
            _c("li", { staticClass: "mb-16" }, [
              _c("p", { staticClass: "mb-4 text-lg" }, [
                _vm._v(_vm._s(_vm.$t("privacypolicy.section3.column03.title"))),
              ]),
              _c("div", { staticClass: "mb-4" }, [
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("privacypolicy.section3.column03.text"))
                  ),
                ]),
              ]),
            ]),
            _c("li", { staticClass: "mb-16" }, [
              _c("p", { staticClass: "mb-4 text-lg" }, [
                _vm._v(_vm._s(_vm.$t("privacypolicy.section3.column04.title"))),
              ]),
              _c("div", { staticClass: "mb-4" }, [
                _c("p", [
                  _vm._v(_vm._s(_vm.$t("privacypolicy.common.company"))),
                ]),
                _c("p", [_vm._v(_vm._s(_vm.$t("privacypolicy.common.ceo")))]),
              ]),
            ]),
            _c("li", { staticClass: "mb-16" }, [
              _c("p", { staticClass: "mb-4 text-lg" }, [
                _vm._v(_vm._s(_vm.$t("privacypolicy.section3.column05.title"))),
              ]),
              _c("ul", { staticClass: "list-none mt-4 ml-0 mb-8" }, [
                _c("li", { staticClass: "mb-1" }, [
                  _vm._v(_vm._s(_vm.$t("privacypolicy.common.inquiriesdesk"))),
                ]),
                _c("li", { staticClass: "mb-1" }, [
                  _vm._v(_vm._s(_vm.$t("privacypolicy.common.email"))),
                ]),
                _c("li", { staticClass: "mb-1" }, [
                  _vm._v(_vm._s(_vm.$t("privacypolicy.common.operationhour"))),
                ]),
                _c("li", { staticClass: "mb-1" }, [
                  _vm._v(_vm._s(_vm.$t("privacypolicy.common.remark"))),
                ]),
              ]),
              _c("ul", { staticClass: "list-none mt-4 ml-0 mb-8" }, [
                _c("li", { staticClass: "mb-1" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacypolicy.section3.column05.authorized.contact"
                      )
                    )
                  ),
                ]),
                _c("li", { staticClass: "mb-1" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "privacypolicy.section3.column05.authorized.remark"
                      )
                    )
                  ),
                ]),
                _c("li", { staticClass: "mb-1" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column05.authorized.item01"
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column05.authorized.organization"
                        )
                      )
                    ),
                  ]),
                ]),
                _c("li", { staticClass: "mb-1" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column05.authorized.item02"
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column05.authorized.complaints"
                        )
                      )
                    ),
                  ]),
                ]),
                _c("li", { staticClass: "mb-1" }, [
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column05.authorized.item03"
                        )
                      )
                    ),
                  ]),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "privacypolicy.section3.column05.authorized.address"
                        )
                      )
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mb-12 pb-12 border-b-2" }, [
          _c("p", { staticClass: "text-lg font-bold mb-4" }, [
            _vm._v(_vm._s(_vm.$t("privacypolicy.section4.title"))),
          ]),
          _c("div", { staticClass: "ml-8 mb-8" }, [
            _c("p", { staticClass: "mb-2" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("privacypolicy.section4.text01")) + " "
              ),
            ]),
            _c("p", { staticClass: "mb-2" }, [
              _vm._v(_vm._s(_vm.$t("privacypolicy.section4.text02"))),
            ]),
            _c("p", { staticClass: "mb-2" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("privacypolicy.section4.text03")) + " "
              ),
              _c("br"),
              _vm._v(" " + _vm._s(_vm.$t("privacypolicy.section4.text04"))),
              _c(
                "a",
                {
                  staticClass: "font-bold underline",
                  attrs: {
                    href: "https://policies.google.com/technologies/ads",
                    target: "_blank",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("privacypolicy.section4.link")))]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }